.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App {
  align-items: center;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.appbody{
  width: 800px; /* Set a specific width for the main column */
  max-width: 100%; /* Ensure it doesn't exceed the container's width */
  padding: 20px; /* Padding inside the main column */
  flex: 0 0 auto; /* No flex-grow, no flex-shrink, auto basis */
  margin: 0 auto; /* This adds automatic margins on both sides, further ensuring centering */
  color: white;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.white {
  color: white;
}

textarea{
  width: 100%;
}  

body {
  background-color: #000000;
  background-image: url('medias/bkg.jpg');
  background-size: 20vh;
}
  
.socialstable {
  width: 100%;
  text-align: center;
}

.right {
  text-align: right;
}

.smallpadding {
  padding-left: 10px;
}

.accordionheader {
  background-color: #3a3a3a;
  color: white;
  cursor: pointer;
  padding: 0.8em;
  width: 100%;
  border: none;
  text-align: justify;
  outline: none;
  font-size: 15px;
  margin-top: 0.6em;
  border-radius: 0.5em;
  transition: 0.4s;
}

.accordionheader:hover {
  background-color: #525252;
}

.presentation {
  background-color: #3a3a3a;
  color: white;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: justify;
  font-size: 15px;
  border-radius: 10px;
}

.presentation a{
  color: white;
}

.presentation a:hover{
  color: rgb(255, 187, 187);
}

.buttonsubmit {
  border-radius: 0.5em;
  border: 2px solid white;

  background-color: #111111;
  color: white;

  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  margin: auto;
  /* set width according to innner content */
  width: fit-content;
  padding: 10px;
}

.faq {
  text-align: left;
  margin-top: 30px;
}

.greywrap {
  background-color: #111111;
  color: white;
  padding: 18px;
  width: 100%;
  border: none;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid rgb(138, 138, 138);
}

/* h2 style in pollwrapper */
.pollwrapper h2 {
  color: white;
}

.pollitem {
  background-color: #252525;
  color: white;
}

/* make the button lighter on hover */
.buttonsubmit:hover {
  background-color: #494949;
  transition: 0.3s;
}


header a, header a:hover, header a:focus, header a:active {
  text-decoration: none;
  color: inherit;
}

a {
  text-decoration: none;
  color: inherit;
  text-decoration: underline;
}

a:hover {
  color: rgb(255, 112, 112);
}

.loginButton {
  color: rgb(255, 255, 255) !important;
  border-radius: 5em;
  border : 2px solid white !important;
  background-color: #000000 !important
}

.loginButton:hover {
  background-color: #494949 !important;
  transition: 0.3s;
}

.scp-image {
  max-width: 100%;
  float: right;
  padding: 10px;
  padding-left: 10px;
  margin-top: 50px;
  padding-left: 50px;
  width: 450px;
}

.justifytext {
  text-align: left;
  text-justify: inter-word;
}

.lastscp-div {
  background-image: url(medias/logo_corner.png);
  background-repeat: no-repeat;
}

.scp-article .justifytext center {
  display: none;
}

.scp-article h3 {
  margin-top: 40px;
}

.archive-item {
  display: flex;
  margin-top: 20px;
  background-color: rgb(27, 27, 27);
  border-radius: 10px;
  color: white;
  padding-left: 20px;
  text-align: left;
}

.archive-item:hover {
  background-color: rgb(49, 49, 49);
}

.archive-item a {
  text-decoration: none;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  width: 100%;
}

.archive-item a p {
  margin-top: auto;
  margin-bottom: auto;
}

.archive-item a:hover {
  text-decoration: none;
  color: white;
}

.archive-item .info {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}

.archive-item .info .badge{
  height: fit-content;
}

.archive-item .info button {
  height: fit-content;
  margin-top: 10px;
  background-color: #424242;
  color: white;
  z-index: 999;
}

.entitycard {
  margin-top: 50px;
  margin-bottom: 50px;
}